import {useState} from "react";
import {Button} from "../components/CommonComponents";
import {getProtocolData} from "../api/serverAPI";
import {useNavigate} from 'react-router-dom';
import {useCookies} from "react-cookie";
import ReactJsAlert from "reactjs-alert"

const Search = () => {
    const [drugNames, setDrugNames] = useState([""]);
    const [protocolNumbers, setProtocolNumbers] = useState([""]);
    const [error, setError] = useState("");
    const [searchResults, setSearchResults] = useState(null); // Track search results
    const navigate = useNavigate();
    const [, setCookie, removeCookie] = useCookies(['apiResponse']);
    const [alertStatus, setAlertStatus] = useState(false);

    const handleDrugChange = (index, event) => {
        const newDrugNames = [...drugNames];
        newDrugNames[index] = event.target.value;
        setDrugNames(newDrugNames);
    };

    const handleProtocolChange = (index, event) => {
        const newProtocolNumbers = [...protocolNumbers];
        newProtocolNumbers[index] = event.target.value;
        setProtocolNumbers(newProtocolNumbers);
    };

    const addDrugField = () => {
        setDrugNames([...drugNames, ""]);
    };

    const addProtocolField = () => {
        setProtocolNumbers([...protocolNumbers, ""]);
    };

    const handleBack = () => {
        setSearchResults(null);
        setDrugNames([""]);
        setProtocolNumbers([""]);
    }

    const handleSearch = () => {
        const filteredDrugNames = drugNames.filter(drug => drug.trim() !== "");
        const filteredProtocolNumbers = protocolNumbers.filter(protocol => protocol.trim() !== "");

        if (!filteredDrugNames.length && !filteredProtocolNumbers.length) {
            setError("Please enter at least one drug name or one protocol number.");
        } else {
            setError("");

            getProtocolData({
                protocol_numbers: filteredProtocolNumbers,
                drug_names: filteredDrugNames
            }).then((response) => {
                if (response.data.protocol_docs) {
                    if (response.data.protocol_docs.length === 0) {
                        setAlertStatus(true);
                    } else {
                        console.log(response.data)
                        setSearchResults(response.data.protocol_docs); // Save search results
                    }
                }
            });
        }
    };

    return (
        <>
            {alertStatus === true && (
                <ReactJsAlert
                    status={alertStatus} // true or false
                    type={"warning"} // success, warning, error, info
                    title={"No such drug/protocol found!"}
                    Close={() => setAlertStatus(false)}
                />
            )}

            {searchResults && (
                <div className="ml-4 mt-5">
                    <Button
                        onClick={handleBack}
                    >Back</Button>
                </div>
            )}

            <div className="flex flex-col items-center min-h-screen">
                {!searchResults && (
                    <>
                        <div className="w-full max-w-3xl p-6 pb-20 mt-20" style={{marginLeft:"50rem"}}>

                            <div className="text-center">
                                <div className="header text-3xl font-bold mt-2 mb-6">FASE</div>
                            </div>

                            <div className="mb-6">
                                <div className="flex items-center space-x-2 mb-2">
                                    <label className="font-medium">Drug Name</label>
                                </div>
                                {drugNames.map((drug, index) => (
                                    <div className="flex space-x-2 mb-2" key={index}>
                                    <textarea
                                        placeholder="Enter drug name"
                                        className="border border-gray-400 p-2 rounded w-full"
                                        onChange={(e) => handleDrugChange(index, e)}
                                        value={drug}
                                        rows={1}
                                        style={{resize: "none", overflow: "hidden"}}
                                    />
                                    </div>
                                ))}
                                <button onClick={addDrugField} className="p-2">
                                    <i className="fa-solid fa-add fa-xl"></i>
                                </button>
                            </div>

                            <div className="mb-6">
                                <div className="flex items-center space-x-2 mb-2">
                                    <label className="font-medium">Protocol Number</label>
                                </div>
                                {protocolNumbers.map((protocol, index) => (
                                    <div className="flex space-x-2 mb-2" key={index}>
                                    <textarea
                                        placeholder="Enter protocol number"
                                        className="border border-gray-400 p-2 rounded w-full"
                                        onChange={(e) => handleProtocolChange(index, e)}
                                        value={protocol}
                                        rows={1}
                                        style={{resize: "none", overflow: "hidden"}}
                                    />
                                    </div>
                                ))}
                                <button onClick={addProtocolField} className="p-2">
                                    <i className="fa-solid fa-add fa-xl"></i>
                                </button>
                            </div>

                            <div className="flex items-start">
                                <Button onClick={handleSearch}>Search</Button>
                            </div>

                            {error && <div className="text-red-500 mt-4">{error}</div>}
                        </div>
                    </>
                )}

                {searchResults && !alertStatus && (
                    <div className="flex flex-col items-start min-h-screen p-4">
                        <div className="overflow-x-auto w-full">
                            <table className="border-collapse border border-gray-400 bg-white table-fixed w-full">
                                <colgroup>
                                    <col style={{width: "200px"}}/>
                                    <col style={{width: "200px"}}/>
                                    {searchResults.map((_, index) => (
                                        <col key={index} style={{minWidth: "40rem"}}/>
                                    ))}
                                </colgroup>

                                <thead>
                                <tr>
                                    <th className="border border-gray-400 px-4 py-2 sticky"></th>
                                    <th className="border border-gray-400 px-4 py-2">Recommended Protocol</th>
                                    <th className="border border-gray-400 px-4 py-2" colSpan={searchResults.length}>
                                        Similar Protocols
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border border-gray-400 px-4 py-2 sticky">Sections</th>
                                    <th className="border border-gray-400 px-4 py-2"></th>

                                    {searchResults.map((protocol, index) => (
                                        <th key={index}
                                            className="border border-gray-400 px-4 py-2">{protocol.drug_name}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Protocol Summary</td>
                                    <td className="border border-gray-400 px-4 py-2">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['protocol summary']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Introduction</td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['introduction']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Trial objectives,
                                        Endpoints and
                                        Estimads
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['trial objectives, endpoints and estimads']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Trial design</td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['trial design']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Trial population</td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['trial population']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Trial intervention and
                                        concomitant
                                        therapy
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['trial intervention and concomitant therapy']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Discontinuation of trial
                                        intervention and
                                        participant withdrawal from trial
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['discontinuation of trial intervention and participant withdrawal from trial']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Trial assessment and
                                        procedures
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['trial assessments and procedures']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Statistical
                                        considerations
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['statistical considerations']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">General considerations:
                                        Regulatory,
                                        ethical and
                                        trial oversight
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['general considerations: regulatory, ethical, and trial oversight']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">General considerations:
                                        Risk
                                        management and quality assurance
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['general considerations: risk management and quality assurance']['content']}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2 align-top sticky">Appendix: Adverse events
                                        and
                                        serious adverse events
                                        - definitions, security and causality
                                    </td>
                                    <td className="border border-gray-400 px-4 py-2 align-top">
                                        {/* Replace with actual recommended protocols content */}

                                    </td>
                                    {searchResults.map((protocol, index) => (
                                        <td key={index}
                                            className="border border-gray-400 px-4 py-2 align-top">{protocol['appendix: adverse events and serious adverse events – definitions, severity, and causality']['content']}</td>
                                    ))}
                                </tr>
                                {/*<tr>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top sticky">Appendix: Definitions and*/}
                                {/*        supporting operational*/}
                                {/*        details*/}
                                {/*    </td>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top">*/}
                                {/*        /!* Replace with actual recommended protocols content *!/*/}

                                {/*    </td>*/}
                                {/*    {searchResults.map((protocol, index) => (*/}
                                {/*        <td key={index}*/}
                                {/*            className="border border-gray-400 px-4 py-2 align-top">{protocol['appendix: definitions and supporting operational details']['content']}</td>*/}
                                {/*    ))}*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top sticky">Appendix: Glossary of*/}
                                {/*        terms*/}
                                {/*    </td>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top">*/}
                                {/*        /!* Replace with actual recommended protocols content *!/*/}

                                {/*    </td>*/}
                                {/*    {searchResults.map((protocol, index) => (*/}
                                {/*        <td key={index}*/}
                                {/*            className="border border-gray-400 px-4 py-2 align-top">{protocol['appendix: glossary of terms']['content']}</td>*/}
                                {/*    ))}*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top sticky">Appendix: References*/}
                                {/*    </td>*/}
                                {/*    <td className="border border-gray-400 px-4 py-2 align-top">*/}
                                {/*        /!* Replace with actual recommended protocols content *!/*/}

                                {/*    </td>*/}
                                {/*    {searchResults.map((protocol, index) => (*/}
                                {/*        <td key={index}*/}
                                {/*            className="border border-gray-400 px-4 py-2 align-top">{protocol['appendix: references']['content']}</td>*/}
                                {/*    ))}*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Search;

import axios from "axios";

export const login = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/login', data, {headers: headers});
}

export const signup = (data) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.post('https://genai.krtrimaiq.ai:8001/signup/', data, {headers: headers});
}

export const getProtocolData = (data) => {
    console.log(data);
    return axios.post("https://med.genai.krtrimaiq.ai/existing_protocols", data);
}

import React, {useEffect, useState} from "react";
import Logo from "../assets/new-logo.png";
import {Link} from "react-router-dom";
import {toast, Toaster} from "react-hot-toast";
import {useCookies} from "react-cookie";

const getInitials = (firstName, lastName) => {
    // Get the first letters of first_name and last_name, make them uppercase
    return (firstName[0] + lastName[0]).toUpperCase();
};

const Navbar = ({ userDetails }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [, setCookie, removeCookie] = useCookies(['authKey', 'email', 'firstName', 'lastName', 'navbarOrganisation']);

    // const logout = async () => {
    //     toggleDropdown(); // Close the dropdown
    //     toast.success("Logout successful");
    //     window.location.reload();
    //     removeCookie('email');
    //     removeCookie('firstName');
    //     removeCookie('lastName');
    //     removeCookie('navbarOrganisation');
    //     removeCookie('authKey');
    // };

    // const handleSettings = async () => {
    //     toggleDropdown();
    //     setIsSettingsModalOpen(true);
    // };

    // const toggleDropdown = () => {
    //     setIsDropdownOpen(!isDropdownOpen);
    // };

    // const openDropdown = () => {
    //     setIsDropdownOpen(true);
    // };

    // useEffect(() => {
    //     // Add event listener to close the dropdown when clicking anywhere on the page
    //     const closeDropdownOnClickOutside = (e) => {
    //         if (isDropdownOpen) {
    //             if (!e.target.closest(".navbar-dropdown")) {
    //                 toggleDropdown();
    //             }
    //         }
    //     };
    //
    //     document.addEventListener("mousedown", closeDropdownOnClickOutside);
    //
    //     return () => {
    //         document.removeEventListener("mousedown", closeDropdownOnClickOutside);
    //     };
    // }, [isDropdownOpen]);

    return (
        <>
        <div className={"fixed top-0 w-full bg-white z-40 drop-shadow-xl"}>
            <nav className={"px-[30px] py-4"}>
                <div className="flex flex-row justify-between content-center items-center">
                    <Link to="/">
                        <img
                            src={Logo}
                            alt="Logo of krtrimaIQ Cognitive Solutions"
                            className="cursor-pointer w-[15rem] h-[4rem]"
                        />
                    </Link>
                    {/*{userDetails ? (*/}
                    <div className="relative">
                        <div className="flex justify-between content-center h-fit text-base" id="user-info">
                            <div>
                                <div className="w-12 h-12 rounded-full bg-[#23479F] text-white text-xl flex items-center justify-center">
                                    {getInitials("Tom", "Walker")}
                                </div>
                            </div>
                            <div className="flex flex-col pe-3">
                                <div className="font-bold">
                                    Tom Walker
                                </div>
                                <div>krtrimaIQ Cognitive Solutions</div>
                            </div>
                        </div>
                        {isDropdownOpen && (
                            <div className="navbar-dropdown absolute top-full mt-2 right-0 w-40 bg-white border border-gray-300 shadow-lg rounded-lg">
                                <button
                                    // onClick={logout}
                                    className="block w-full text-left px-4 py-2 hover:bg-light-blue focus:outline-none rounded-t cursor-pointer"
                                >
                                    Log Out
                                </button>
                                <button
                                    // onClick={handleSettings}
                                    className="block w-full text-left px-4 py-2 hover:bg-light-blue focus:outline-none rounded-t cursor-pointer"
                                >
                                    Settings
                                </button>
                                <hr className="border-t border-gray-200" />
                            </div>
                        )}
                    </div>
                    {/*) : null}*/}
                </div>
            </nav>
            <Toaster position="top-center" reverseOrder={false} />

        </div>
            {/*<SettingsModal*/}
            {/*    isOpen={isSettingsModalOpen}*/}
            {/*    onClose={() => setIsSettingsModalOpen(false)}*/}
            {/*/>*/}
            </>
    );
};

export default Navbar;

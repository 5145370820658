import React, {useEffect, useState, useContext} from "react";
import Navbar from "./Navbar";
import axios from "axios";
import {useCookies} from 'react-cookie';
import Sidebar from "./Sidebar";

const Layout = ({isAuthenticated, children}) => {
    const [cookies] = useCookies(['firstName', 'lastName', 'navbarOrganisation', 'authKey']);

    let userDetails;
    if (cookies.firstName) {
        const keys = ['firstName', 'lastName', 'organization'];
        const result = [cookies.firstName, cookies.lastName, cookies.navbarOrganisation];
        userDetails = Object.fromEntries(keys.map((key, index) => [key, result[index]]));
    }

    return (
        <>
            <Navbar userDetails={userDetails}/>
            <div className="mt-3">
                <div className="flex gap-4 w-full">
                    <div className="mt-20">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;

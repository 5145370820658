import "./App.css";
import React, {useState, useEffect} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import Layout from "./components/Layout";
import {useCookies} from 'react-cookie';
import Search from "./pages/Search";

const App = () => {
    const [cookies] = useCookies(['firstName', 'lastName', 'navbarOrganisation', 'authKey']);
    const firstName = cookies['firstName'];
    const authKey = cookies['authKey'];

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Function to set authentication status
    const setAuthenticationStatus = (status) => {
        setIsAuthenticated(status);
    };

    // Check if the user is authenticated
    useEffect(() => {
        if (firstName) {
            setAuthenticationStatus(true);
        } else {
            setAuthenticationStatus(false);
        }
    }, [firstName, cookies]);

    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Search/>}/>
            </Routes>
        </Layout>
    )
};

export default App;
